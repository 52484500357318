import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import 'babel-polyfill';
import config from './config';
import store from './store';
import PayoutOrderProvider from './store/context/payoutOrder';
import './style/index.less';
import Root from './pages/Root';
window.config = config;
ReactDom.render(React.createElement(Provider, { store: store },
    React.createElement(PayoutOrderProvider, null,
        React.createElement(Root, null))), document.getElementById('app'));
