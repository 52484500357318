var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useLayoutEffect, useState } from 'react';
import Select from "antd/lib/select";
import ASelect from '@/components/AntdCustomComponent/ASelect';
import { user } from '@/api';
import * as intl from 'react-intl-universal';
import AModal from '@/components/AntdCustomComponent/AModal';
import CreateMerchant from './CreateMerchant';
const { Option } = Select;
export default function CompanySelector(props) {
    const { shopId, uemail } = props;
    const [state, setState] = useState({
        isCreateMerchant: false,
        isSubmitted: false,
        defaultShopId: shopId,
        companyShopOptions: []
    });
    const companyChangeHandler = (company) => __awaiter(this, void 0, void 0, function* () {
        setState(Object.assign(Object.assign({}, state), { defaultShopId: company, isCreateMerchant: company === 'create_merchant' ? true : false }));
    });
    const onCancel = () => {
        setState(Object.assign(Object.assign({}, state), { isCreateMerchant: false, isSubmitted: false, defaultShopId: shopId }));
    };
    const init = () => __awaiter(this, void 0, void 0, function* () {
        user.getCompanyShopOverview()
            .then((res) => {
            if (res.ret_code === 0) {
                const formattedOptionList = [
                    ...res.ret_msg.map((x) => {
                        return {
                            label: x.shopname,
                            value: x.shopid.toString()
                        };
                    }),
                    {
                        label: intl.get('Global.create_merchant'),
                        value: 'create_merchant'
                    }
                ];
                setState(Object.assign(Object.assign({}, state), { companyShopOptions: formattedOptionList }));
            }
        })
            .catch((err) => err);
    });
    useLayoutEffect(() => {
        init();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(ASelect, { className: "company-selector", onChange: companyChangeHandler, style: { marginLeft: '5px', width: '175px' }, value: state.defaultShopId }, state.companyShopOptions.map((company) => (React.createElement(Option, { value: company.value, key: company.value }, company.label)))),
        React.createElement(AModal, { visible: state.isCreateMerchant, footer: null, title: false, onCancel: onCancel, closable: !state.isSubmitted },
            React.createElement(CreateMerchant, { onCancel: onCancel, setState: setState, state: state, uemail: uemail }))));
}
